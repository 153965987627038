/* eslint-disable max-len */
import { getWeatherIcon } from '../../helpers/weatherIcons';
import Loading from '../Loading/Loading';
import React from 'react';
import { useSelector } from 'react-redux';
import { TIMESTAMP_HOUR, MILLISECONDS_TO_SECONDS } from '../../config/config';

const Weather = () => {
    const weatherData = useSelector((state) => state.appModel.weatherData);
    const isLoading = weatherData === null;

    const renderFiveHourForecast = () => {
        if (!weatherData || !weatherData.hourly) {
            return <Loading width="813px" />;
        }

        const currentTimestamp = Date.now() / MILLISECONDS_TO_SECONDS;
        const startOfHourTimestamp = Math.floor(currentTimestamp / TIMESTAMP_HOUR) * TIMESTAMP_HOUR;

        return (
            <table className="table-auto w-full text-center text-custom-xxs">
                <thead>
                    <tr>
                        {weatherData.hourly.slice(0, 5).map((forecast, index) => (
                            <th
                                key={forecast.dt}
                                className={`border-solid border-barclays-mid-green ${
                                    index === 0
                                        ? 'max-w-[136px] border-l-0 text-left pl-9'
                                        : 'max-w-[67px] border-l-4'
                                }`}
                            >
                                {index === 0
                                    ? 'Now'
                                    : new Date(
                                          (startOfHourTimestamp + index * TIMESTAMP_HOUR) *
                                              MILLISECONDS_TO_SECONDS,
                                      ).toLocaleTimeString([], {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                      })}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {weatherData.hourly.slice(0, 5).map((forecast, index) => (
                            <td
                                key={`img_${forecast.dt}`}
                                className={`align-middle h-auto border-solid border-barclays-mid-green ${
                                    index === 0 ? 'border-l-0' : 'border-l-4'
                                } ${index === 4 ? 'pl-1' : ''}`}
                            >
                                {forecast.weather[0] ? (
                                    <img
                                        src={getWeatherIcon(forecast.weather[0].description)}
                                        alt={forecast.weather[0].description}
                                        className={`h-auto mx-auto ${
                                            index === 0 ? 'w-[136px]' : 'w-[67px]'
                                        }`}
                                    />
                                ) : (
                                    <Loading width="137px" />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        {weatherData.hourly.slice(0, 5).map((forecast, index) => (
                            <td
                                key={`temp_${forecast.dt}`}
                                className={`text-custom-sm align-middle h-auto border-solid border-barclays-mid-green pb-7 ${
                                    index === 0 ? 'border-l-0 text-left pl-9' : 'border-l-4'
                                }`}
                            >
                                {forecast.temp ? (
                                    `${Math.round(forecast.temp)}°c`
                                ) : (
                                    <Loading width="137px" />
                                )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    };

    return (
        <section
            className={`pt-0 pb-0 bg-barclays-light-green -mt-10 ${
                isLoading ? 'h-[332px]' : 'h-auto'
            }`}
        >
            {isLoading ? (
                <Loading height="h-full" width="813px" />
            ) : (
                <>
                    <p className="pl-9 text-custom-lg effra-italic-bold">5 hour forecast</p>
                    {renderFiveHourForecast()}
                </>
            )}
        </section>
    );
};

export default Weather;
