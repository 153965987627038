/* eslint-disable camelcase */

import {
    fetchWeatherData,
    fetchSolisCloudData,
    fetchNakedEnergyPlantData,
    fetchCmsSensor,
    fetchCmsApiData,
    fetchSolarEdgeData,
} from '../helpers/api';

// ======================================================================
// STATE
// ======================================================================

// set initial values for the state
const state = {
    inited: false,

    // OpenWeather API
    weatherData: null,

    // Data from CMS
    dataFromCms: null,

    // #1a this is the raw from the 4 API's
    roofSolarData: null,
    carportSolarData: null,
    hotWaterSolarData: null,
    consumptionData: null,

    // #2a roof solar data values TBC
    // Defined directly in the SolarSection Component which is imported into the Solar Component

    // #2b carport solar values TBC
    // Defined directly in the SolarSection Component which is imported into the Solar Component

    // #2c hot water solar values
    currentPowerGenHotWaterSolar: 0,
    dayPowerGenHotWaterSolar: 0,
    totalPowerGenHotWaterSolar: 0,

    // #2d consumption values TBC
    // Being calculated in the respective functions below

    // #3 values calculated with maths
    energySinceReopening: 0,
    percentOfElectricityConsumed: 0,
};

// ======================================================================
// REDUCERS
// ======================================================================

const reducers = {
    setState(rootState, payload) {
        return { ...rootState, ...payload };
    },
};

// ======================================================================
// EFFECTS
// ======================================================================

const effects = (dispatch) => ({
    init(payload, rootState) {
        dispatch.appModel.fetchAllData();
    },

    refreshData(payload, rootState) {
        // set all the data to null so we see loading indicators in the front end
        dispatch.appModel.setState({
            weatherData: null,
            dataFromCms: null,
            roofSolarData: null,
            carportSolarData: null,
            hotWaterSolarData: null,
            consumptionData: null,
        });

        dispatch.appModel.fetchAllData();
    },

    fetchAllData(payload, rootState) {
        // 1. fetch weather
        dispatch.appModel.fetchWeatherData();
        // 2. fetch roof solar (solar edge / sol PV)
        dispatch.appModel.fetchRoofSolarData();
        // 3. fetch carport solar (Ren-Energy / Solis)
        dispatch.appModel.fetchCarportSolarData();
        // 4. fetch hot water solar (naked energy)
        dispatch.appModel.fetchHotWaterSolarData();
        // 5. fetch consumption (79 group / BMS / Learnd)
        dispatch.appModel.fetchConsumptionData();
        // 6. Data from CMS
        dispatch.appModel.fetchCmsData();
    },

    async fetchCmsData(payload, rootState) {
        const response = await fetchCmsApiData();
        dispatch.appModel.setState({ dataFromCms: response });
    },

    async fetchWeatherData(payload, rootState) {
        const response = await fetchWeatherData();
        dispatch.appModel.setState({ weatherData: response });
    },

    async fetchRoofSolarData(payload, rootState) {
        const response = await fetchSolarEdgeData();
        dispatch.appModel.setState({ roofSolarData: response });
        dispatch.appModel.doMaths();
    },

    async fetchCarportSolarData(payload, rootState) {
        const response = await fetchSolisCloudData();
        const carportData = response?.data?.page?.records[0] || {};

        dispatch.appModel.setState({
            carportSolarData: {
                ...response.data,
                monthEnergy: carportData.monthEnergy || 'N/A',
                monthEnergyStr: carportData.monthEnergyStr || 'N/A',
            },
        });

        dispatch.appModel.doMaths();
    },

    async fetchHotWaterSolarData(payload, rootState) {
        const response = await fetchNakedEnergyPlantData();
        const { generation_kwh, power_kw } = response;
        const currentPower = power_kw.pv + power_kw.thermal;
        const dayPowerGen = generation_kwh.pv.day + generation_kwh.thermal.day;
        const totalPowerGen = generation_kwh.pv.total + generation_kwh.thermal.total;

        dispatch.appModel.setState({
            hotWaterSolarData: response,
            currentPowerGenHotWaterSolar: currentPower,
            dayPowerGenHotWaterSolar: dayPowerGen,
            totalPowerGenHotWaterSolar: totalPowerGen,
        });

        dispatch.appModel.doMaths();
    },

    async fetchConsumptionData(payload, rootState) {
        const response = await fetchCmsSensor();
        // Add up all of the _last_month values (z)
        const specificPoints = [
            'DB_1F_Lighting_Power_Last_Month',
            'DB_GF_Lighting_Power_Last_Month',
            'DB_GF_Mechanical_Last_Month',
            'DB_Photovoltaic_Last_Month',
            'DB_Plant_Room_Last_Month',
        ];
        const relevantPoints = response.data.apiData.filter((entry) =>
            specificPoints.includes(entry.point),
        );
        const sumOfValues = relevantPoints.reduce((acc, entry) => acc + entry.value, 0);

        dispatch.appModel.setState({
            consumptionData: response,
            lastMonthValues: sumOfValues,
        });

        dispatch.appModel.doMaths();
    },

    doMaths(payload, rootState) {
        const { roofSolarData, carportSolarData, hotWaterSolarData, consumptionData } =
            rootState.appModel;

        // check that we have all the data we need
        if (roofSolarData && carportSolarData && hotWaterSolarData && consumptionData) {
            // calculate G, H & I here and save the values using dispatch.appModel.setState

            // G
            dispatch.appModel.calculateEnergySinceReopening();

            // H
            /*
                Already worked out the above calculation so it's just:
                energySinceReopening / AVERAGE_UK_HOME_CONSUMPTION_KWH
                Which is being done in the TotalStats component directly after processing the data
            */

            // I
            dispatch.appModel.calculatePercentageOfElectricity();
        }
    },

    // Calculating G value
    calculateEnergySinceReopening(_, rootState) {
        const { roofSolarData, carportSolarData, totalPowerGenHotWaterSolar } = rootState.appModel;

        // roofSolarData (x1)
        const { lifeTimeData } = roofSolarData?.data?.overview;
        const lifeTimeDataEnergy = lifeTimeData.energy / 1000;

        // carportSolarData (x2)
        const records = carportSolarData?.page?.records || [];
        const allEnergy = records[0].allEnergy || {};
        const allEnergyStr = records[0].allEnergyStr || {};

        let allEnergyActual = allEnergy;
        if (allEnergy && allEnergyStr && !isNaN(allEnergy) && allEnergyStr === 'MWh') {
            // convert MWh to kWh, if required
            allEnergyActual = allEnergy * 1000;
        }

        // hotWaterSolarData (x3)
        // totalPowerGenHotWaterSolar coming from hotWaterSolarData async fn

        const calculatedEnergySinceReopening =
            isNaN(allEnergyActual) || isNaN(totalPowerGenHotWaterSolar)
                ? 'Invalid numbers'
                : lifeTimeDataEnergy + allEnergyActual + totalPowerGenHotWaterSolar;

        dispatch.appModel.setState({ energySinceReopening: calculatedEnergySinceReopening });
    },

    // Calculating I value
    calculatePercentageOfElectricity(_, rootState) {
        const { roofSolarData, carportSolarData, hotWaterSolarData } = rootState.appModel;

        // roofSolarData (y1)
        const { lastMonthData } = roofSolarData?.data?.overview;
        const lastMonthDataEnergy = lastMonthData.energy / 1000;

        // carportSolarData (y2)
        const { monthEnergy } = carportSolarData;

        // hotWaterSolarData (y3)
        const { generation_kwh } = hotWaterSolarData || {};
        const { pv, thermal } = generation_kwh || {};
        const lastCalendarMonthPV = pv?.last_calendar_month;
        const lastCalendarMonthThermal = thermal?.last_calendar_month;

        // Z value
        const zValue = rootState.appModel.lastMonthValues;

        const isValid =
            [
                typeof lastMonthDataEnergy,
                typeof monthEnergy,
                typeof lastCalendarMonthPV,
                typeof lastCalendarMonthThermal,
            ].every((type) => type === 'number') && zValue !== 0;

        const calculatedPercentageOfElectricityConsumed = isValid
            ? ((lastMonthDataEnergy +
                  monthEnergy +
                  lastCalendarMonthPV +
                  lastCalendarMonthThermal) /
                  zValue) *
              100
            : 'Invalid Numbers';

        dispatch.appModel.setState({
            percentOfElectricityConsumed: calculatedPercentageOfElectricityConsumed,
        });
    },
});

// ======================================================================
// EXPORT
// ======================================================================

export const appModel = {
    // initial state
    state,
    // reducers - handle state changes with pure functions
    reducers,
    // effects - handle state changes with impure functions
    // (use async/await for async actions)
    effects,
};
