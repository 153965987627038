export const AVERAGE_UK_HOME_CONSUMPTION_KWH = 2900;
export const CMS_BASE_URL = `${process.env.REACT_APP_WORDPRESS_URL}/?time=${Date.now()}`;
export const CMS_URL = `${process.env.REACT_APP_WORDPRESS_URL}/`;
export const EXCLUDE = 'minutely,daily,alerts';
export const LAT = '52.2';
export const LON = '0.1';
export const MILLISECONDS_TO_SECONDS = 1000; // Conversion factor from milliseconds to seconds
export const NAKED_ENERGY_API_TOKEN = 'd054285162735b038efa920449ad2a0287ac3d01';
export const NAKED_ENERGY_BASE_URL = 'https://clarity247.nakedenergy.com';
export const SOLAR_EDGE_API_TOKEN = `d9a3f1c7aee20a7a2a15cf2a93894d18`;
export const SOLISCLOUD_TOKEN = '3463dfe1bf3f06a21232f8c0db218960';
export const TIMESTAMP_HOUR = 3600;
export const WEATHER_API_KEY = '81c7c2eedd1401ab3449d8e13fe076b2';
export const WEATHER_BASE_URL = 'https://api.openweathermap.org/data/3.0/onecall';
export const REFRESH_INTERVAL_15MIN = 15 * 60 * 1000;
// export const REFRESH_INTERVAL_TEST = 30000;
