import { AVERAGE_UK_HOME_CONSUMPTION_KWH } from '../../config/config';
import Loading from '../Loading/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    formatEnergySinceReopening,
    formatNumberClosestWholeDecimal,
} from '../../helpers/formatNumber';

const TotalStats = () => {
    const energySinceReopening = useSelector((state) => state.appModel.energySinceReopening);
    const percentageConsumed = useSelector((state) => state.appModel.percentOfElectricityConsumed);

    const LoadingContainer = ({ isLoading, children }) => (
        <span>{isLoading ? <Loading width="90px" /> : children}</span>
    );

    return (
        <section className="bg-barclays-dark-green text-balance">
            <div className="grid grid-cols-2 gap-2 p-9 pb-28 text-center justify-center">
                <div>
                    <img
                        src="/total-solar.svg"
                        alt="Total solar"
                        className="w-auto max-h-[82px] mx-auto mb-5"
                    />
                    <p className="effra text-custom-xxs leading-8">
                        <LoadingContainer isLoading={!energySinceReopening}>
                            <span className="effra-italic-bold text-custom-sm">
                                {formatEnergySinceReopening(energySinceReopening)}
                            </span>{' '}
                            MWh of solar has been generated onsite since December 2023
                        </LoadingContainer>
                    </p>
                </div>
                <div>
                    <img
                        src="/home-power.svg"
                        alt="Home power"
                        className="w-auto max-h-[82px] mx-auto mb-5"
                    />
                    <p className="effra text-custom-xxs leading-8 mb-3">
                        <LoadingContainer isLoading={!energySinceReopening}>
                            That&apos;s enough to power{' '}
                            <span className="effra-italic-bold text-custom-sm">
                                {formatNumberClosestWholeDecimal(
                                    energySinceReopening / AVERAGE_UK_HOME_CONSUMPTION_KWH,
                                )}
                            </span>{' '}
                            average UK homes for an
                            <br />
                            entire year
                        </LoadingContainer>
                    </p>
                    <small className="block leading-4">
                        Typical annual electricity consumption of UK household is 2,900kWh/year -
                        Ofgem
                    </small>
                </div>
                <div className="col-span-2 mt-0 mb-12">
                    <div className="flex justify-center gap-14 hidden">
                        <img
                            src="/electricity-consumed.svg"
                            alt="Electricity consumed"
                            className="max-w-[60px] h-auto"
                        />
                        <div
                            className={`effra text-custom-xxs text-left leading-7 ${
                                !percentageConsumed ? 'w-[100px]' : ''
                            }`}
                        >
                            <LoadingContainer isLoading={!percentageConsumed}>
                                <span className="effra-italic-bold text-custom-sm">
                                    {formatNumberClosestWholeDecimal(percentageConsumed)}
                                </span>{' '}
                                % of electricity consumed by the building in the last calendar month
                                was generated by on-site solar. The remainder is sourced from
                                REGO-backed green tariffs.
                            </LoadingContainer>
                        </div>
                    </div>
                </div>
                <small className="col-span-2 text-left mb-12">
                    The data reflected here is our live indicative performance data and has not been
                    audited. As such, it may differ from annual reporting. Ofgem statistics based on
                    UK excluding Northern Ireland and correct as of October 2023.
                </small>
                <div className="w-full col-span-2">
                    <img src="/eaglelabsbarclays.png" alt="Eagle labs" />
                </div>
            </div>
        </section>
    );
};

TotalStats.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.any,
};

export default TotalStats;
