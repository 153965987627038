import brokenCloudsIcon from '../assets/icons/broken_clouds.svg';
import clearSkyIcon from '../assets/icons/clear_sky.svg';
import fewCloudsIcon from '../assets/icons/few_clouds.svg';
import freezingIcon from '../assets/icons/freezing.svg';
import mistIcon from '../assets/icons/mist.svg';
import rainIcon from '../assets/icons/rain.svg';
import scatteredCloudsIcon from '../assets/icons/scattered_clouds.svg';
import showerRainIcon from '../assets/icons/shower_rain.svg';
import sleetIcon from '../assets/icons/sleet.svg';
import snowIcon from '../assets/icons/snow.svg';
import thunderstormIcon from '../assets/icons/thunderstorm.svg';
import windyIcon from '../assets/icons/windy.svg';

const weatherIcons = [
    { description: 'broken clouds', icon: brokenCloudsIcon },
    { description: 'overcast clouds', icon: brokenCloudsIcon },
    { description: 'clear sky', icon: clearSkyIcon },
    { description: 'few clouds', icon: fewCloudsIcon },
    { description: 'freezing', icon: freezingIcon },
    { description: 'mist', icon: mistIcon },
    { description: 'smoke', icon: mistIcon },
    { description: 'haze', icon: mistIcon },
    { description: 'dust', icon: mistIcon },
    { description: 'fog', icon: mistIcon },
    { description: 'sand', icon: mistIcon },
    { description: 'ash', icon: mistIcon },
    { description: 'squall', icon: mistIcon },
    { description: 'tornado', icon: mistIcon },
    { description: 'rain', icon: rainIcon },
    { description: 'scattered clouds', icon: scatteredCloudsIcon },
    { description: 'shower rain', icon: showerRainIcon },
    { description: 'sleet', icon: sleetIcon },
    { description: 'snow', icon: snowIcon },
    { description: 'thunderstorm', icon: thunderstormIcon },
    { description: 'windy', icon: windyIcon },
];

export const getWeatherIcon = (description) => {
    const lowercaseDescription = description.toLowerCase();
    const matchedIcon = weatherIcons.find((iconObj) =>
        lowercaseDescription.includes(iconObj.description),
    );
    return matchedIcon ? matchedIcon.icon : null;
};
