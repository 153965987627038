/* eslint-disable max-len */
import axios from 'axios';
import {
    CMS_BASE_URL,
    CMS_URL,
    EXCLUDE,
    LAT,
    LON,
    NAKED_ENERGY_API_TOKEN,
    NAKED_ENERGY_BASE_URL,
    SOLAR_EDGE_API_TOKEN,
    SOLISCLOUD_TOKEN,
    WEATHER_API_KEY,
    WEATHER_BASE_URL,
} from '../config/config';

export const fetchWeatherData = async () => {
    try {
        const response = await axios.get(
            `${WEATHER_BASE_URL}?lat=${LAT}&lon=${LON}&exclude=${EXCLUDE}&appid=${WEATHER_API_KEY}&units=metric`,
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching weather data:', error);
        throw error;
    }
};

export const fetchCmsApiData = async () => {
    try {
        const response = await axios.get(CMS_BASE_URL);
        return response.data;
    } catch (error) {
        console.error('Error fetching weather data:', error);
        throw error;
    }
};

export const fetchCmsSensor = async () => {
    try {
        const response = await axios.get(`${CMS_URL}wp-json/headless/v1/sensor_get`);
        return response.data;
    } catch (error) {
        console.error('Error fetching weather data:', error);
        throw error;
    }
};

export const fetchSolarEdgeData = async () => {
    try {
        const response = await axios.get(
            `${CMS_URL}wp-json/headless/v1/solaredge_get?token=${SOLAR_EDGE_API_TOKEN}`,
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching SolarEdge data:', error);
        throw error;
    }
};

export const fetchSolisCloudData = async () => {
    try {
        const response = await axios.get(
            `${CMS_URL}wp-json/headless/v1/soliscloud_get?token=${SOLISCLOUD_TOKEN}`,
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching SolisCloud data:', error);
        throw error;
    }
};

export const fetchNakedEnergyPlantData = async () => {
    try {
        const response = await axios.get(`${NAKED_ENERGY_BASE_URL}/api/plants/13/generation`, {
            headers: {
                Authorization: `Token ${NAKED_ENERGY_API_TOKEN}`,
            },
        });
        const data = response.data;

        return data;
    } catch (error) {
        console.error('Error fetching NakedEnergy data:', error);
        throw error;
    }
};
