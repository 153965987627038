import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../Loading/Loading';

const SolarSection = ({
    imgSrc,
    title,
    currentPower,
    powerUnit,
    energyGenerated,
    energyUnit,
    isLast,
    isLoading,
}) => (
    <div
        className={`
            flex flex-col items-center justify-center border-solid border-barclays-light-green ${
                isLast ? '' : 'border-r-4'
            } p-3 pt-7 pb-7 h-ful`}
    >
        <img className="w-auto h-[76px] mx-auto mb-5" src={imgSrc} alt={title} />
        <p className="effra-italic-bold leading-10 mb-6 text-custom-sm">{title}</p>
        <div className="mb-6">
            <p className="text-custom-xxs effra leading-7">Current power generation</p>
            {isLoading ? (
                <Loading width="52px" />
            ) : (
                <p className="effra-italic-bold text-custom-sm">
                    {currentPower} <span className="effra-bold">{powerUnit}</span>
                </p>
            )}
        </div>
        <div>
            <p className="text-custom-xxs effra leading-7">
                Energy
                <br />
                generated today
            </p>

            {isLoading ? (
                <Loading width="52px" />
            ) : (
                <p className="effra-italic-bold text-custom-sm">
                    {energyGenerated} <span className="effra-bold">{energyUnit}</span>
                </p>
            )}
        </div>
    </div>
);

SolarSection.propTypes = {
    imgSrc: PropTypes.string,
    title: PropTypes.string,
    currentPower: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    powerUnit: PropTypes.string,
    energyGenerated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    energyUnit: PropTypes.string,
    isLast: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default SolarSection;
