/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import Weather from '../Weather/Weather';
import { MILLISECONDS_TO_SECONDS, CMS_URL } from '../../config/config';
import { motion, AnimatePresence } from 'framer-motion';
import Solar from '../Solar/Solar';
import Header from '../Header/Header';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
// import YouTube from 'react-youtube';

const Carousel = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { media_slider, background_image, dashboard_timer } = data.message.post.acf;
    const videoRef = useRef(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (media_slider.length > 0) {
            clearTimeout(intervalRef.current);

            let nextIndex = (currentIndex + 1) % (media_slider.length + 1);

            while (media_slider[nextIndex - 1]?.media_type === 'none') {
                nextIndex = (nextIndex + 1) % (media_slider.length + 1);
            }

            intervalRef.current = setTimeout(
                () => {
                    setCurrentIndex(nextIndex);
                },
                currentIndex === 0
                    ? dashboard_timer * 1000
                    : media_slider[currentIndex - 1]?.duration_seconds * MILLISECONDS_TO_SECONDS,
            );
        }

        return () => clearTimeout(intervalRef.current);
    }, [media_slider, currentIndex]);
    //     useEffect(() => {
    //         const script = document.createElement('script');
    //         script.src = 'https://www.youtube.com/iframe_api';

    //         document.head.appendChild(script);

    //         script.onload = () => {
    //             window.onYouTubeIframeAPIReady = () => {
    //                 window.YT.Player('youtube-embed', {
    //                     videoId: extractVideoId(embedCode),
    //                     events: {
    //                         onReady: (event) => {
    //                             event.target.playVideo();
    //                         },
    //                     },
    //                 });
    //             };
    //         };

    //         return () => {
    //             document.head.removeChild(script);
    //         };
    //     }, [embedCode]);

    //     const extractVideoId = (url) => {
    //         // Extract the video ID from the YouTube URL
    //         const match = url.match(
    //             /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
    //         );
    //         return match && match[1];
    //     };

    //     return <div id="youtube-embed"></div>;
    // };

    // const YouTubeEmbed = ({ videoId }) => {
    //     const opts = {
    //         playerVars: {
    //             muted: 1,
    //             controls: 0,
    //             autoplay: 1,
    //             height: '1920',
    //             width: '1080',
    //             origin: 'http://localhost:3000',
    //         },
    //     };

    //     return <YouTube videoId={videoId} opts={opts} />;
    // };

    // const extractVideoId = (url) => {
    //     const match = url.match(
    //         // eslint-disable-next-line no-useless-escape
    //         /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
    //     );
    //     return match && match[1];
    // };

    const renderMedia = (index) => {
        const mediaClass = 'w-[1080px] h-[1920px] absolute top-0 left-0 object-cover object-center';
        const mainClasses = background_image ? 'bg-black' : 'bg-barclays-blue';

        if (index === 0) {
            return (
                <div
                    style={{
                        backgroundImage:
                            background_image && background_image.url
                                ? `url(${CMS_URL + background_image.url})`
                                : 'none',
                    }}
                    className={`
                        ${mainClasses}
                        flex flex-col justify-center items-center
                        relative w-[1080px] h-[1920px] bg-center bg-cover`}
                >
                    <div className="w-[813px] h-[1650px] overflow-hidden bg-barclays-light-green">
                        <Header />
                        <Weather />
                        <Solar />
                    </div>
                </div>
            );
        } else {
            const currentItem = media_slider[index - 1];

            if (currentItem.media_type === 'image') {
                const mediaURL = `${CMS_URL}${currentItem.image.url}`;
                return <img src={mediaURL} alt={currentItem.image.alt} className={mediaClass} />;
            } else if (currentItem.media_type === 'video') {
                const videoURL = `${CMS_URL}${currentItem.video.url}`;
                return (
                    <video
                        autoPlay
                        height="1920"
                        muted
                        ref={videoRef}
                        className={mediaClass}
                        width="1080"
                    >
                        <source src={videoURL} type={currentItem.video.mime_type} />
                        Your browser does not support the video tag.
                    </video>
                );
            }

            // else if (currentItem.media_type === 'embed') {
            //     return <div dangerouslySetInnerHTML={{ __html: currentItem.embed }}></div>;
            //     const videoId = extractVideoId(currentItem.embed);
            //     return <YouTubeEmbed videoId={videoId} />;
            // }
        }

        return null;
    };

    return (
        <div>
            <AnimatePresence>
                <motion.div
                    key={currentIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                >
                    {renderMedia(currentIndex)}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

Carousel.propTypes = {
    data: PropTypes.shape({
        message: PropTypes.shape({
            post: PropTypes.shape({
                acf: PropTypes.shape({
                    background_image: PropTypes.oneOfType([
                        PropTypes.bool,
                        PropTypes.shape({
                            url: PropTypes.string,
                        }),
                    ]),
                    media_slider: PropTypes.oneOfType([
                        PropTypes.bool,
                        PropTypes.arrayOf(
                            PropTypes.shape({
                                media_type: PropTypes.string,
                                image: PropTypes.oneOfType([
                                    PropTypes.bool,
                                    PropTypes.shape({
                                        url: PropTypes.string,
                                        alt: PropTypes.string,
                                    }),
                                ]),
                                video: PropTypes.oneOfType([
                                    PropTypes.bool,
                                    PropTypes.shape({
                                        url: PropTypes.string,
                                        mime_type: PropTypes.string,
                                    }),
                                ]),
                                embed: PropTypes.string,
                                duration_seconds: PropTypes.string,
                            }),
                        ),
                    ]),
                    dashboard_timer: PropTypes.string,
                }),
            }),
        }),
    }),
    embedCode: PropTypes.string,
    videoId: PropTypes.string,
};

export default Carousel;
