import { init } from '@rematch/core';
import { appModel } from './models.app';

const store = init({
    models: {
        appModel,
    },
});

export default store;
