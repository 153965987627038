/**
 * Converts it to a string with two decimal places then checks if it ends with '.00'.
 * If the condition is true, it removes the last three characters
 */
export const formatNumber = (number) => {
    const formattedNumber = Number(number).toFixed(2);
    return formattedNumber.endsWith('.00') ? formattedNumber.slice(0, -3) : formattedNumber;
};

// Round to the closest whole number with 1 decimal point
export const formatNumberClosestWholeDecimal = (number) => {
    const roundedNumber = Math.round(Number(number) * 10) / 10;
    return roundedNumber.toString();
};

/**
 * Checks if the number is greater than or equal to 10,000.
 * If it is, it rounds the number to two decimal places and
 * appends 'k' to represent thousands.
 *
 * If the number is less than 10,000, it rounds it to the nearest whole number.
 */
export const formatEnergySinceReopening = (number) => {
    if (isNaN(number)) {
        return 'N/A';
    }

    if (number >= 10000) {
        const roundedNumber = number / 1000;
        return `${roundedNumber.toFixed(2)}`;
    } else {
        return Math.round(number).toString();
    }
};
