import Loading from '../Loading/Loading';
import React, { useState } from 'react';

const Header = () => {
    const [isLoading] = useState(false);

    return (
        <section className="effra-italic-bold bg-barclays-light-green p-9 pb-0 pt-28">
            {isLoading ? (
                <Loading height="251px" />
            ) : (
                <>
                    <p className="text-custom-lg">Today in</p>
                    <h1 className="text-custom-xl -mt-10">Cambridge</h1>
                </>
            )}
        </section>
    );
};

export default Header;
