import React from 'react';

const NotFound = () => {
    return (
        <div
            className="
                bg-barclays-light-green 
                effra 
                flex
                flex-col 
                justify-center
                items-center
                w-full
                text-barclays-font-color
                relative
                h-full"
        >
            <img className="w-[240px] h-auto mb-[65px]" src="/404.svg" alt="Not found" />
            <p className="effra-italic-bold text-custom-lg text-center">
                I&apos;m not working right now,
                <br />
                please call back later.
            </p>
        </div>
    );
};

export default NotFound;
