/* eslint-disable no-unused-vars */
import React from 'react';
import NotFound from '../components/Loading/NotFound';
import { motion, AnimatePresence } from 'framer-motion';
import Carousel from '../components/Carousel/Carousel';
import { useSelector } from 'react-redux';

const DefaultPage = () => {
    const dataFromCms = useSelector((state) => state.appModel.dataFromCms);

    return (
        <main
            className="
            effra max-w-display w-full text-barclays-font-color relative h-[1920px] bg-black"
        >
            <img
                src="/barclays.svg"
                alt="Barclays"
                className="w-auto absolute top-[28px] right-[35px] h-[90px] z-10"
            />
            <AnimatePresence>
                {dataFromCms ? (
                    <>
                        <Carousel data={dataFromCms} />
                    </>
                ) : (
                    <motion.div
                        className="w-full h-[1920px]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 1 }}
                    >
                        <NotFound />
                    </motion.div>
                )}
            </AnimatePresence>
        </main>
    );
};

export default DefaultPage;
