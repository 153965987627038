import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ width, height }) => {
    return (
        <span className={`${height} w-[${width}] flex items-center justify-center`}>
            <img
                className="max-w-[90px] animate-rotate360"
                src="/refresh-data.svg"
                alt="Refreshing data..."
            />
        </span>
    );
};

Loading.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};

export default Loading;
