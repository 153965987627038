import PropTypes from 'prop-types';
import React from 'react';
import SolarSection from '../SolarSection/SolarSection';
import TotalStats from '../TotalStats/TotalStats';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../helpers/formatNumber';

const Solar = () => {
    const carportSolarData = useSelector((state) => state.appModel.carportSolarData);
    const roofSolarData = useSelector((state) => state.appModel.roofSolarData);
    const hotWaterSolarData = useSelector((state) => state.appModel.hotWaterSolarData);
    const currentPowerGenHotWaterSolar = useSelector(
        (state) => state.appModel.currentPowerGenHotWaterSolar,
    );
    const dayPowerGenHotWaterSolar = useSelector(
        (state) => state.appModel.dayPowerGenHotWaterSolar,
    );

    return (
        <section className="bg-barclays-mid-green">
            <div className="grid grid-cols-3 gap-2 text-center items-center">
                <SolarSection
                    imgSrc="/roof-solar.svg"
                    title="Roof solar"
                    currentPower={
                        formatNumber(roofSolarData?.data?.overview?.currentPower?.power / 1000) ||
                        'N/A'
                    }
                    powerUnit={'kW'}
                    energyGenerated={
                        formatNumber(roofSolarData?.data?.overview?.lastDayData?.energy / 1000) ||
                        'N/A'
                    }
                    energyUnit={'kWh'}
                    isLoading={!roofSolarData}
                />
                <SolarSection
                    imgSrc="/carport-solar.svg"
                    title="Carport solar"
                    currentPower={formatNumber(carportSolarData?.page?.records[0]?.power) || 'N/A'}
                    powerUnit={'kW'}
                    energyGenerated={
                        formatNumber(carportSolarData?.page?.records[0]?.dayEnergy) || 'N/A'
                    }
                    energyUnit={'kWh'}
                    isLoading={!carportSolarData}
                />
                <SolarSection
                    imgSrc="/hot-water-solar.svg"
                    title="Hot water solar"
                    currentPower={formatNumber(currentPowerGenHotWaterSolar) || 'N/A'}
                    powerUnit={'kW'}
                    energyGenerated={formatNumber(dayPowerGenHotWaterSolar) || 'N/A'}
                    energyUnit={'kWh'}
                    isLast
                    isLoading={!hotWaterSolarData}
                />
            </div>
            <TotalStats />
        </section>
    );
};

Solar.propTypes = {
    isLoading: PropTypes.bool,
    carportSolarData: PropTypes.object,
    nakedEnergyPlantData: PropTypes.object,
    currentPowerGenHotWaterSolar: PropTypes.number,
    dayPowerGenHotWaterSolar: PropTypes.number,
    isLast: PropTypes.bool,
};

export default Solar;
