// eslint-disable-next-line no-unused-vars
import { CMS_URL, REFRESH_INTERVAL_15MIN, REFRESH_INTERVAL_TEST } from './config/config';
import DefaultPage from '../src/pages/DefaultPage';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const App = () => {
    const dataFromCms = useSelector((state) => state.appModel.dataFromCms);
    const dispatch = useDispatch();

    const refreshData = () => {
        dispatch.appModel.refreshData();
        console.log('Data refreshed at:', new Date());
    };

    useEffect(() => {
        dispatch.appModel.init();

        /*
            If you refresh the data at 15-minute intervals, 
            you would make 96 calls to the OpenWeather API in a 24-hour period.
            
            limit is 2000.
         */
        const refreshInterval = setInterval(refreshData, REFRESH_INTERVAL_15MIN);

        return () => clearInterval(refreshInterval);
    }, []);

    if (dataFromCms) {
        return (
            <DefaultPage
                backgroundImage={CMS_URL + dataFromCms.message.post.acf.background_image.url}
            />
        );
    }
};

export default App;
